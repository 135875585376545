<template>
  <PageUnderConstruction :link="linkToOldUI" link-text="Check Nameserver here" :external="true" />
</template>

<script>
import PageUnderConstruction from '@/pages/app/PageUnderConstruction.vue'

export default {
  components: {
    PageUnderConstruction
  },
  data() {
    return {
      linkToOldUI: process.env.VUE_APP_OLD_UI_URL + '/manage.php?mode=nscheck'
    }
  }
}
</script>
