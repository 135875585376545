<template>
  <div id="nameserver-check">
    <NameserverCheckIndex />
  </div>
</template>

<script>
import NameserverCheckIndex from '@/components/tools/nameserver-check/NameserverCheckIndex'

export default {
  components: {
    NameserverCheckIndex
  }
}
</script>
